<toaster-container [toasterconfig]="toasterconfig"></toaster-container>
<div *ngIf="inited">
  <ng-container *ngIf="userService.loggedIn && isAdminPage">
    <div class="app-header navbar">
      <button class="navbar-toggler sidebar-toggler" type="button" data-toggle="sidebar-show" (click)="appSidebar.toggleMinimized()">
        <span class="navbar-toggler-icon"></span>
      </button>
      <a class="navbar-brand" routerLink="/">
        <img class="navbar-brand-full" src="favicon.png" height="42">
      </a>
      <ul class="nav navbar-nav">
        <li class="nav-item px-3 header-subtitle">{{page}}</li>
      </ul>
      <ul class="nav navbar-nav ml-auto mr-2">
        <ul class="nav navbar-nav ml-auto mr-3" *ngIf="impersonating">
          <div class="btn btn-outline-danger" (click)="stopImpersonating()">Stop impersonating {{impersonating}}</div>
        </ul>
        <ul class="nav navbar-nav ml-auto mr-2" *ngIf="isAppReady">
          <app-queue-manager></app-queue-manager>
        </ul>
        <ul class="nav navbar-nav ml-auto mr-2" *ngIf="!isAppReady">
          Connecting to server...
        </ul>
        <ul class="nav navbar-nav ml-auto mr-2">
          <li class="nav-item dropdown" dropdown placement="bottom right">
            <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle (click)="false">
              <img src="images/user.svg" class="img-avatar" width="36" height="36" />
            </a>
            <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
              <a class="dropdown-item" href="javascript:;" (click)="changePassword()"><i class="fa fa-key"></i> Change Password</a>
              <a class="dropdown-item" href="#" (click)="userService.logout()"><i class="fa fa-lock"></i> Logout</a>
            </div>
          </li>
        </ul>
      </ul>
    </div>
    <div class="app-body">
      <app-sidebar #appSidebar [fixed]="true" [display]="'lg'" [minimized]="sidebarMinimized" (minimizedChange)="toggleMinimize($event)" class="hide-on-print">
        <app-sidebar-nav [navItems]="navItems" [perfectScrollbar] [disabled]="appSidebar.minimized"></app-sidebar-nav>
        <app-sidebar-minimizer></app-sidebar-minimizer>
      </app-sidebar>
      <main class="main">
        <div class="container-fluid bg-white p-0">
          <router-outlet></router-outlet>
        </div>
      </main>
    </div>
  </ng-container>
  <ng-container *ngIf="!userService.loggedIn || !isAdminPage">
    <router-outlet></router-outlet>
  </ng-container>
</div>
