<div (clickOutside)="close()" [clickOutsideEnabled]="clickOutsideEnabled" class="fluid-width-filter"
     *ngIf="!queue.loadingQueue">
  <div class="btn btn-outline-primary" [ngClass]="{'active': panelVisible}" (click)="toggle()">{{fullLabel()}}</div>
  <div *ngIf="panelVisible" class="queue-manager" style="right: 0">
    <div class="queue-tabs">
      <div class="queue-tab" [ngClass]="{'active': activeTab === 'finished'}" (click)="openTab('finished')">Finished</div>
      <div class="queue-tab" [ngClass]="{'active': activeTab === 'in-progress'}" (click)="openTab('in-progress')">In progress</div>
      <div class="queue-tab queue-tab-no-click cursor-pointer" (click)="toggleQueue()"
           [ngbTooltip]="queue.data.queueStopped ? 'Start queue' : 'Stop queue'" placement="left">
        <span class="fa text-danger" [ngClass]="{
        'fa-stop': !queue.data.queueStopped,
        'text-danger': !queue.data.queueStopped,
        'fa-play': queue.data.queueStopped,
        'text-success': queue.data.queueStopped
        }"></span>
      </div>
    </div>

    <div class="queue-operations-container">
      <div class="queue-operations" *ngIf="activeTab === 'in-progress'">
        <ng-container *ngIf="queue.data.unfinished.length">
          <div class="queue-operation" *ngFor="let operation of queue.data.unfinished">
            <span class="operation-title">{{(operation.operation + (operation.source ? " (" + operation.source + ")" : ""))}}</span>
            <span class="operation-added text-success text-right text-small pr-3" *ngIf="!operation.started">Added on {{operation.createdAt | date:'M/d/y HH:mm'}}</span>
            <span class="operation-started text-success text-right text-small pr-3" *ngIf="operation.started">
              <progressbar [value]="[{value: queue.data.currentProgress, label: ' ' + queue.data.currentProgress + '%'}]"
                           [striped]="true" [animate]="true" type="info" [max]="100"></progressbar>
            </span>
            <span class="operation-remove text-right">
              <span class="fa text-danger fa-remove cursor-pointer" (click)="selectedForRemoval = operation; removeItemWarningModal.show()"
                    [ngbTooltip]="'Remove operation from queue'" placement="left"></span>
            </span>
          </div>
        </ng-container>
        <ng-container *ngIf="!queue.data.unfinished.length">
          <span>No operations in progress.</span>
        </ng-container>
      </div>

      <div class="queue-operations" *ngIf="activeTab === 'finished'">
        <ng-container *ngIf="queue.data.finished.length">
          <div class="queue-operation" *ngFor="let operation of queue.data.finished">
            <span class="operation-title">{{(operation.operation + (operation.source ? " (" + operation.source + ")" : ""))}}</span>
            <span class="operation-added text-success text-right text-small pr-3">
              <span class="d-block">Finished on {{operation.finishedOn | date:'M/d/y HH:mm'}}</span>
              <span class="d-block text-secondary">{{operation.durationString}}</span>
            </span>
            <span class="operation-action text-primary text-right">
              <span class="fa fa-download cursor-pointer" *ngIf="operation.source === 'CSV'" (click)="downloadData(operation)"
                    [ngbTooltip]="'Download CSV file'" placement="left"></span>
            </span>
          </div>
        </ng-container>
        <ng-container *ngIf="!queue.data.finished.length">
          <span>No operations finished.</span>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<span *ngIf="queue.loadingQueue">Loading queue...</span>

<div bsModal #removeItemWarningModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-warning modal-dialog-centered" role="document">
    <div class="modal-content text-center">
      <div class="modal-body pb-0">
        <span>Are you sure you wish to remove this operation from the queue?</span>
      </div>
      <div class="modal-footer border-0 justify-content-center">
        <button type="button" class="btn btn-secondary modal-btn" (click)="removeItemWarningModal.hide()">Cancel</button>
        <button type="button" class="btn btn-warning modal-btn" (click)="removeItem()">Remove</button>
      </div>
    </div>
  </div>
</div>
