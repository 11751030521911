<div class="ds-preloader" style="display: none;"></div>

<header style="position: relative; z-index: 0; background: none;">

  <div class="ds-overlay ds-overlay-color"></div>

  <div class="container">
    <div class="row">
      <div class="col-sm-4 ds-logo text-center font-weight-bolder">
        <a href="/" title="">
          <img src="images/logo-1x.png" class="ds-logo-1x" alt="" style="width: 250px">
        </a>
        <div class="logo-subtitle">By Karma Solutions LLC</div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-md-7 ds-title">
        <h1>Privacy Policy</h1>
      </div>
    </div>

  </div>

  <div class="backstretch"
       style="left: 0px; top: 0px; overflow: hidden; margin: 0px; padding: 0px; z-index: -999998; position: absolute; width: 100%; height: 843px;">
    <img src="images/bg.jpg"
         style="position: absolute; margin: 0px; padding: 0px; border: none; max-height: none; max-width: none; z-index: -999999; width: 100%; left: 0px; top: -24.5156px;">
  </div>
</header>

<div class="product module pb-4 mb-4">
  <div class="container">
    <div class="row">
      <div class="col-md-12 ds-product">
        <p>At AppForHumans.com, we value the privacy of our users and are committed to protecting the personal information shared with us. This Privacy Policy outlines how we collect, use, store, and disclose information when you use our SaaS (Software-as-a-Service) platform, including our website and any related services (collectively referred to as the "Services"). Please take the time to read this Privacy Policy carefully to understand our practices regarding your personal data.</p>
      </div>
    </div>
  </div>
</div>

<div class="product module py-4 my-4">
  <div class="container">
    <div class="row">
      <div class="col-md-12 ds-product">
        <h3>1. Information We Collect</h3>
        <p><strong>1.1 Personal Information</strong><br>
          When you use our Services, we may collect personal information that can be used to identify you, such as your name, email address, postal address, phone number, and payment information. We collect this information when you create an account, make a purchase, contact our customer support, or otherwise interact with our Services.
<br><br>
          <strong>1.2 Usage Data</strong><br>
          In addition to personal information, we also collect certain usage data automatically when you use our Services. This may include your IP address, browser type, operating system, device information, the pages you visit, and the actions you take while using our Services. We may also collect information through cookies and similar technologies as described in Section 4 of this Privacy Policy.</p>
      </div>
    </div>
  </div>
</div>

<div class="product module py-4 my-4">
  <div class="container">
    <div class="row">
      <div class="col-md-12 ds-product">
        <h3>2. How We Use Your Information</h3>
        <p><strong>2.1 Providing and Improving our Services</strong><br>
          We use the information we collect to provide, maintain, and improve our Services, including features such as inventory management, automatic repricing, and integration with the Amazon Ads API. We may also use your information to personalize your experience, respond to your inquiries or requests, and communicate with you about our Services, including updates and promotional offers.
          <br><br>
          <strong>2.2 Analytics and Research</strong><br>
          We may aggregate and anonymize the data we collect to analyze user behavior, trends, and preferences to improve our Services, develop new features, and conduct research. This analysis is performed in a way that does not personally identify you.
          <br><br>
            <strong>2.3 Advertising and Marketing</strong><br>
          With your consent, we may use your personal information to provide you with personalized advertisements, promotions, and marketing communications related to our Services or third-party products and services that we believe may interest you. You can opt out of receiving these communications at any time by following the instructions provided in the communication or by contacting us directly.
          <br><br>
              <strong>2.4 Legal Compliance and Protection</strong><br>
          We may use and disclose your information to comply with applicable laws, regulations, legal processes, or enforceable governmental requests. We may also use and disclose your information to protect the rights, property, or safety of AppForHumans.com, our users, or others, and to detect and prevent fraud or any other unauthorized or illegal activities.</p>
      </div>
    </div>
  </div>
</div>

<div class="product module py-4 my-4">
  <div class="container">
    <div class="row">
      <div class="col-md-12 ds-product">
        <h3>3. Information Sharing and Disclosure</h3>
        <p><strong>3.1 Service Providers</strong><br>
          We may engage trusted third-party service providers to perform certain functions on our behalf. These service providers will have access to your personal information only to the extent necessary to provide the services requested and are contractually obligated to protect your information and maintain its confidentiality.
          <br><br>
          <strong>3.2 Business Transfers</strong><br>
          In the event of a merger, acquisition, or any other form of sale or transfer of some or all of our assets, we may disclose or transfer your information as part of the transaction. However, we will notify you and provide you with the opportunity to opt out of such transfer if your personal information becomes subject to a different privacy policy.
          <br><br>
            <strong>3.3 Aggregated and Non-Identifying Information</strong><br>
          We may share aggregated and anonymized information with third parties for various purposes, including analytics, research, and marketing. This information does not personally identify you and is used in a manner that is consistent with this Privacy Policy.
          <br><br>
              <strong>3.4 Legal Requirements</strong><br>
          We may disclose your information to comply with legal obligations, enforce our policies, respond to claims, or protect the rights, property, or safety of AppForHumans.com, our users, or others.</p>
      </div>
    </div>
  </div>
</div>

<div class="product module py-4 my-4">
  <div class="container">
    <div class="row">
      <div class="col-md-12 ds-product">
        <h3>4. Cookies and Similar Technologies</h3>
        <p>We may use cookies, beacons, tags, and other similar technologies to collect information automatically as you use our Services. These technologies help us personalize your experience, understand how you interact with our Services, and improve our offerings. You have the option to disable cookies through your browser settings, but please note that certain features of our Services may not function properly if cookies are disabled.</p>
      </div>
    </div>
  </div>
</div>

<div class="product module py-4 my-4">
  <div class="container">
    <div class="row">
      <div class="col-md-12 ds-product">
        <h3>5. Data Security</h3>
        <p>We employ industry-standard security measures to protect your information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee the absolute security of your information.</p>
      </div>
    </div>
  </div>
</div>

<div class="product module py-4 my-4">
  <div class="container">
    <div class="row">
      <div class="col-md-12 ds-product">
        <h3>6. Your Rights and Choices</h3>
        <p>You have certain rights regarding your personal information, including the right to access, correct, or delete your information. You can also object to the processing of your personal information or withdraw your consent where applicable. To exercise these rights or for any privacy-related inquiries, please contact us using the information provided at the end of this Privacy Policy.</p>
      </div>
    </div>
  </div>
</div>

<div class="product module py-4 my-4">
  <div class="container">
    <div class="row">
      <div class="col-md-12 ds-product">
        <h3>7. Third-Party Links and Services</h3>
        <p>Our Services may contain links to third-party websites, services, or applications that are not owned or controlled by Repricer. We are not responsible for the privacy practices of these third parties, and we encourage you to review their privacy policies before providing any personal information.</p>
      </div>
    </div>
  </div>
</div>

<div class="product module py-4 my-4">
  <div class="container">
    <div class="row">
      <div class="col-md-12 ds-product">
        <h3>8. Children's Privacy</h3>
        <p>Our Services are not intended for use by individuals under the age of 16, and we do not knowingly collect personal information from children. If you become aware that a child has provided us with personal information without parental consent, please contact us, and we will take steps to remove such information and terminate the child's account.</p>
      </div>
    </div>
  </div>
</div>

<div class="product module py-4 my-4">
  <div class="container">
    <div class="row">
      <div class="col-md-12 ds-product">
        <h3>9. Changes to this Privacy Policy</h3>
        <p>We may update this Privacy Policy from time to time to reflect changes in our practices or applicable laws. We will notify you of any material changes by posting the updated Privacy Policy on our website or through other communication channels. We encourage you to review this Privacy Policy periodically for the latest information about our privacy practices.</p>
      </div>
    </div>
  </div>
</div>

<div class="footer module">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 ds-footer-copyrights">
        <p>© {{currentDate | date:'yyyy'}} Karma Solutions LLC. All Rights Reserved.</p>
      </div>
    </div>
  </div>
</div>
