import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {ToasterService as ToastrService} from 'angular2-toaster';
import {DOCUMENT} from '@angular/common';
import {UserService} from './user.service';
import * as localConfig from '../../../config/local-fe.js';

@Injectable({
  providedIn: 'root',
})
export class QueueService {
  changeDetector = null;
  data = {
    finished: [],
    unfinished: [],
    queueStopped: false,
    currentProgress: 0
  };

  loadingQueue = true;

  constructor(@Inject(DOCUMENT) private document: HTMLDocument,
              public http: HttpClient,
              public modalService: NgbModal,
              public router: Router,
              public toastr: ToastrService,
              public user: UserService) {
    if (this.user.loggedIn) {
      this.user.refreshProfile(() => {
        this.setupSubscriptions();
        this.getQueueItems();
      });
    }
    else {
      this.setupSubscriptions();
      this.getQueueItems();
    }
  }

  connectSocketIfNotConnected() {
    window['io'].sails.url = localConfig.operationsServer;
    window['io'].sails.reconnection = true;

    try {
      if (!window['io'].socket || window['io'].socket.url !== window['io'].sails.url) {
        if (window['io'].socket) window['io'].socket.disconnect();
        window['io'].socket = window['io'].sails.connect(window['io'].sails.url);
      }
    }
    catch(e) {
      console.log(e);
    }
  }

  async setupSubscriptions() {
    this.connectSocketIfNotConnected();

    if (!this.user.loggedIn) return;

    window['io'].socket.on('queue', (msg) => {
      if (msg.tenantID !== this.user.tenantID) return;

      if (msg.action === "updated") {
        this.getQueueItems();
      }
    });

    window['io'].socket.on('operation-progress', (msg) => {
      if (msg.tenantID !== this.user.tenantID) return;

      let running = this.getCurrentRunningOperation();
      if (running === msg.operation) {
        this.data.currentProgress = msg.progress;
        this.detectChanges();
      }
    });
  }

  getCurrentRunningOperation() {
    let runningOperation = this.data.unfinished.filter(operation => operation.started)[0] || null;
    return runningOperation ? runningOperation.originalOperation : null;
  }

  async getQueueItems() {
    if (!this.user.loggedIn) return;

    let items:any = await this.http.get(localConfig.operationsServer + '/api/queue/list').toPromise();
    this.parseItems(items.finished);
    this.parseItems(items.unfinished);
    this.data = items;
    this.loadingQueue = false;

    this.detectChanges();
  }

  parseItems(items) {
    items.forEach(item => {
      if (item.started) this.calculateDuration(item);
      item.originalOperation = item.operation;

      if (item.operation === "import") {
        item.operation = "Import";
        item.source = item.source === "manual" ? "Manual" : (item.source === "automatic" ? "Automatic" : item.source);
      }

      if (item.operation === "sync-archived") {
        item.operation = "Sync Archived";
        item.source = item.source === "manual" ? "Manual" : (item.source === "automatic" ? "Automatic" : item.source);
      }

      if (item.operation === "update" && item.source === "CSV") {
        item.operation = "Update";
      }

      if (item.operation === "update" && item.source === "UI") {
        item.operation = "Update";
      }

      if (item.operation === "update" && item.source === "Assignments") {
        item.operation = "Update";
      }

      if (item.operation === "update" && item.source === "orders") {
        item.operation = "Update";
        item.source = "Inventory Sync";
      }

      if (item.operation === "update" && item.source === "component") {
        item.operation = "Update";
        item.source = "Inventory Sync";
      }

      if (item.operation === "update" && item.source === "shipments") {
        item.operation = "Update";
        item.source = "Inventory Sync";
      }

      if (item.operation === "update" && item.source === "manual") {
        item.operation = "Update";
        item.source = "Inventory Sync";
      }

      if (item.operation === "update" && item.source === "automatic") {
        item.operation = "Update";
        item.source = "Inventory Sync";
      }

      if (item.operation === "update-components") {
        item.operation = "Update Components";
      }

      if (item.operation === 'update-inventories') {
        item.operation = 'Update Inventories';
      }

      if (item.operation === 'update-supplier-orders') {
        item.operation = 'Update Supplier Orders';
      }

      if (item.operation === "update-products") {
        item.operation = "Update Products";
      }

      if (item.operation === "detect-hijackers") {
        item.operation = "Detect Hijackers";
        item.source = "";
      }

      if (item.operation === "check-conditions" ||
          item.operation === "velocity-repricing") {
        item.operation = "Velocity Repricing";
        item.source = "";
      }

      if (item.operation === "scrape-bsrs") {
        item.operation = "Scrape BSRs";
        item.source = "";
      }

      if (item.operation === "scrape-sellers") {
        item.operation = "Scrape Sellers";
        item.source = "";
      }

      if (item.operation === "check-business-prices") {
        item.operation = "Sync Business Prices";
        item.source = "";
      }

      if (item.operation === "sync-orders") {
        item.operation = "Sync Orders";
        item.source = "";
      }

      if (item.operation === 'sync-financial-events') {
        item.operation = 'Sync Financial Events';
        item.source = '';
      }

      if (item.operation === "get-advertising-data") {
        item.operation = "Get Advertising Data";
        item.source = "";
      }

      if (item.operation === "sync-cancellations") {
        item.operation = "Sync Cancellations";
        item.source = "";
      }

      if (item.operation === "archive-listings") {
        item.operation = "Archive Listings";
        item.source = "";
      }

      if (item.operation === "sync-prices") {
        item.operation = "Sync Prices";
        item.source = "";
      }

      if (item.operation === "sync-prices-manually") {
        item.operation = "Sync Prices";
        item.source = "Manual";
      }

      if (item.operation === "sync-returns") {
        item.operation = "Sync Returns";
        item.source = "";
      }

      if (item.operation === "check-returns") {
        item.operation = "Check Returns";
        item.source = "";
      }

      if (item.operation === "sync-shipments") {
        item.operation = "Sync Shipments";
        item.source = "";
      }

      if (item.operation === "generate-shipping-plans") {
        item.operation = "Generate Shipping Plans";
        item.source = "";
      }

      if (item.operation === "generate-purchase-orders") {
        item.operation = "Generate Purchase Orders";
        item.source = "";
      }

      if (item.operation === "perform-liquidation") {
        item.operation = "Perform Liquidation";
        item.source = "";
      }

      if (item.operation === "scrape-average-reviews") {
        item.operation = "Scrape Average Reviews";
        item.source = "";
      }

      if (item.operation === "update-rates") {
        item.operation = "Update Exchange Rates";
        item.source = "";
      }

      if (item.operation === "sync-assignments") {
        item.operation = "Sync Assignments";
        item.source = "";
      }

      if (item.operation === "update-progress-estimates") {
        item.operation = "Maintenance Task";
        item.source = "";
      }

      if (item.operation === "check-price-ranges") {
        item.operation = "Check Price Ranges";
        item.source = "";
      }

      if (item.operation === "generate-low-stock-report") {
        item.operation = "Generate Low Stock Report";
        item.source = "";
      }

      if (item.operation === "clean-old-logs") {
        item.operation = "Maintenance Task";
        item.source = "";
      }

      if (item.operation === "pregenerate-reports") {
        item.operation = "Prepare " + item.source + " Reports";
        item.source = "";
      }

      if (item.operation === "refresh-caches") {
        item.operation = "Refresh Caches";
        item.source = "";
      }

      if (item.operation === "scrape-average-reviews-manually") {
        item.operation = "Scrape Average Reviews";
        item.source = "";
      }
    });
  }

  recalculateStartedItemDuration() {
    let startedItem = this.data.unfinished.filter(item => item.started)[0] || null;
    if (startedItem) this.calculateDuration(startedItem);
  }

  calculateDuration(item) {
    let started = new Date(item.startedOn);
    let ended = item.finishedOn ? new Date(item.finishedOn) : new Date();
    item.duration = Math.ceil((ended.getTime() - started.getTime()) / 1000);
    if (item.duration) {
      item.durationString = item.finishedOn ? 'Finished in ' : 'Started ';
      if (item.duration > 3600) {
        let hours = Math.floor(item.duration / 3600);
        let minutes = Math.floor((item.duration - (hours * 3600)) / 60);
        item.durationString += hours;
        item.durationString += hours === 1 ? " hour" : " hours";
        item.durationString += " and ";
        item.durationString += minutes;
        item.durationString += minutes === 1 ? " minute" : " minutes";
      }
      else if (item.duration > 60) {
        let minutes = Math.floor(item.duration / 60);
        item.durationString += minutes;
        item.durationString += minutes === 1 ? " minute" : " minutes";
      }
      else {
        item.durationString += item.duration;
        item.durationString += item.duration === 1 ? " second" : " seconds";
      }
    }
  }

  detectChanges() {
    if (this.changeDetector) this.changeDetector.detectChanges();
  }
}
