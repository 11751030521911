import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from "@angular/common";
import {HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS} from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {UserService} from "./_services/user.service";
import {GuardService} from "./_services/guard.service";
import {ToasterModule} from 'angular2-toaster';

import { P404Component } from './_routes/error/404.component';
import { P500Component } from './_routes/error/500.component';

import {JwtModule} from "@auth0/angular-jwt";
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";

import {ModalModule} from "ngx-bootstrap/modal";
import {AuthInterceptor} from "./_services/http-interceptor.interceptor";

import {AppAsideModule, AppBreadcrumbModule, AppSidebarModule, AppFooterModule, AppHeaderModule} from "@coreui/angular";
import {PerfectScrollbarModule} from "ngx-perfect-scrollbar";
import {ChangePasswordComponent} from "./_routes/dashboard/users/change-password/change-password.component";
import {NgbModalModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {QueueManagerComponent} from './elements/queue-manager/queue-manager.component';
import {ClickOutsideModule} from 'ng-click-outside';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import {IsManagerService} from './_services/isManager.service';
import {IsAdminService} from './_services/isAdmin.service';
import {ToastrConfigService} from './_services/toastr-config.service';
import {PrivacyPolicyComponent} from './_routes/website/privacy-policy/privacy-policy.component';

import * as localConfig from '../../config/local-fe.js';

export function tokenGetter() {
  return localStorage.getItem('token');
}

@NgModule({
  declarations: [
    AppComponent,
    P404Component,
    P500Component,
    ChangePasswordComponent,
    QueueManagerComponent,
    PrivacyPolicyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    HttpClientModule,
    HttpClientJsonpModule,
    BsDatepickerModule.forRoot(),
    ToasterModule.forRoot(),
    BrowserAnimationsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: [
            localConfig.apiServer
                .replace('https://', '')
                .replace('http://', ''),
            localConfig.operationsServer
                .replace('https://', '')
                .replace('http://', '')
        ],
      }
    }),
    ModalModule,
    PerfectScrollbarModule,
    NgbTooltipModule,
    BsDropdownModule.forRoot(),
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    NgbModalModule,
    ClickOutsideModule,
    ProgressbarModule.forRoot()
  ],
  providers: [
    UserService,
    ToastrConfigService,
    GuardService,
    IsManagerService,
    IsAdminService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
