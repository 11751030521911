module.exports = {
  appName: 'AppForHumans.com',

  cacheBucket: 'repricer-cache',

  oAuthSPAPI: {
    // North America
    A2Q3Y263D00KWC: 'https://sellercentral.amazon.com.br/apps/authorize/consent?application_id=amzn1.sp.solution.92b769b9-f47e-4c9f-acb4-f9624d2b69dd', // Brazil
    A2EUQ1WTGCTBG2: 'https://sellercentral.amazon.ca/apps/authorize/consent?application_id=amzn1.sp.solution.92b769b9-f47e-4c9f-acb4-f9624d2b69dd', // Canada
    A1AM78C64UM0Y8: 'https://sellercentral.amazon.com.mx/apps/authorize/consent?application_id=amzn1.sp.solution.92b769b9-f47e-4c9f-acb4-f9624d2b69dd', // Mexico
    ATVPDKIKX0DER: 'https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.92b769b9-f47e-4c9f-acb4-f9624d2b69dd', // US

    // Europe
    A2VIGQ35RCS4UG: 'https://sellercentral.amazon.ae/apps/authorize/consent?application_id=amzn1.sp.solution.92b769b9-f47e-4c9f-acb4-f9624d2b69dd', // United Arab Emirates (U.A.E.)
    A1PA6795UKMFR9: 'https://sellercentral.amazon.de/apps/authorize/consent?application_id=amzn1.sp.solution.92b769b9-f47e-4c9f-acb4-f9624d2b69dd', // Germany
    ARBP9OOSHTCHU: 'https://sell.amazon.eg/apps/authorize/consent?application_id=amzn1.sp.solution.92b769b9-f47e-4c9f-acb4-f9624d2b69dd', // Egypt
    A1RKKUPIHCS9HS: 'https://sellercentral.amazon.es/apps/authorize/consent?application_id=amzn1.sp.solution.92b769b9-f47e-4c9f-acb4-f9624d2b69dd', // Spain
    A13V1IB3VIYZZH: 'https://sellercentral.amazon.fr/apps/authorize/consent?application_id=amzn1.sp.solution.92b769b9-f47e-4c9f-acb4-f9624d2b69dd', // France
    A1F83G8C2ARO7P: 'https://sellercentral.amazon.co.uk/apps/authorize/consent?application_id=amzn1.sp.solution.92b769b9-f47e-4c9f-acb4-f9624d2b69dd', // UK
    A21TJRUUN4KGV: 'https://sell.amazon.in/apps/authorize/consent?application_id=amzn1.sp.solution.92b769b9-f47e-4c9f-acb4-f9624d2b69dd', // India
    APJ6JRA9NG5V4: 'https://sellercentral.amazon.it/apps/authorize/consent?application_id=amzn1.sp.solution.92b769b9-f47e-4c9f-acb4-f9624d2b69dd', // Italy
    A1805IZSGTT6HS: 'https://sellercentral.amazon.nl/apps/authorize/consent?application_id=amzn1.sp.solution.92b769b9-f47e-4c9f-acb4-f9624d2b69dd', // Netherlands
    A1C3SOZRARQ6R3: 'https://sellercentral.amazon.pl/apps/authorize/consent?application_id=amzn1.sp.solution.92b769b9-f47e-4c9f-acb4-f9624d2b69dd', // Poland
    A17E79C6D8DWNP: 'https://sellercentral.amazon.sa/apps/authorize/consent?application_id=amzn1.sp.solution.92b769b9-f47e-4c9f-acb4-f9624d2b69dd', // Saudi Arabia
    A2NODRKZP88ZB9: 'https://sellercentral.amazon.se/apps/authorize/consent?application_id=amzn1.sp.solution.92b769b9-f47e-4c9f-acb4-f9624d2b69dd', // Sweden
    A33AVAJ2PDY3EV: 'https://sellercentral.amazon.com.tr/apps/authorize/consent?application_id=amzn1.sp.solution.92b769b9-f47e-4c9f-acb4-f9624d2b69dd', // Turkey
    AMEN7PMS3EDWL: 'https://sellercentral.amazon.com.be/apps/authorize/consent?application_id=amzn1.sp.solution.92b769b9-f47e-4c9f-acb4-f9624d2b69dd', // Belgium

    // Far East
    A19VAU5U5O7RUS: 'https://sellercentral.amazon.sg/apps/authorize/consent?application_id=amzn1.sp.solution.92b769b9-f47e-4c9f-acb4-f9624d2b69dd', // Singapore
    A39IBJ37TRP1C6: 'https://sellercentral.amazon.com.au/apps/authorize/consent?application_id=amzn1.sp.solution.92b769b9-f47e-4c9f-acb4-f9624d2b69dd', // Australia
    A1VC38T7YXB528: 'https://sellercentral.amazon.co.jp/apps/authorize/consent?application_id=amzn1.sp.solution.92b769b9-f47e-4c9f-acb4-f9624d2b69dd' // Japan
  },

  oAuthAmazonAds: {
    'na': 'https://www.amazon.com/ap/oa',
    'eu': 'https://eu.account.amazon.com/ap/oa',
    'fe': 'https://apac.account.amazon.com/ap/oa'
  },

  amazonAdsClientID: 'amzn1.application-oa2-client.9a6d2cc3316a4a5ea0dad0e50093f036',
  amazonAdsScope: 'advertising::campaign_management',

  stripeMode: 'test',
  stripeCustomerPortals: {
    test: 'billing.stripe.com/p/login/test_dR6fZBf0tdqL3CM8ww',
    live: 'billing.stripe.com/p/login/3cs00SeOcgq2fxCdQQ'
  },

  currencyByMarketplaceID: {
    A2Q3Y263D00KWC: 'BRL', // Brazil
    A2EUQ1WTGCTBG2: 'CAD', // Canada
    A1AM78C64UM0Y8: 'MXN', // Mexico
    ATVPDKIKX0DER: 'USD',  // US
    A2VIGQ35RCS4UG: 'AED', // United Arab Emirates (U.A.E.)
    A1PA6795UKMFR9: 'EUR', // Germany
    ARBP9OOSHTCHU: 'EGP',  // Egypt
    A1RKKUPIHCS9HS: 'EUR', // Spain
    A13V1IB3VIYZZH: 'EUR', // France
    A1F83G8C2ARO7P: 'GBP', // UK
    A21TJRUUN4KGV: 'INR',  // India
    APJ6JRA9NG5V4: 'EUR',  // Italy
    A1805IZSGTT6HS: 'EUR', // Netherlands
    A1C3SOZRARQ6R3: 'PLN', // Poland
    A17E79C6D8DWNP: 'SAR', // Saudi Arabia
    A2NODRKZP88ZB9: 'SEK', // Sweden
    A33AVAJ2PDY3EV: 'TRY', // Turkey
    AMEN7PMS3EDWL: 'EUR',  // Belgium
    A19VAU5U5O7RUS: 'SGD', // Singapore
    A39IBJ37TRP1C6: 'AUD', // Australia
    A1VC38T7YXB528: 'JPY'  // Japan
  },

  countryByMarketplaceID: {
    A2Q3Y263D00KWC: 'Brazil',
    A2EUQ1WTGCTBG2: 'Canada',
    A1AM78C64UM0Y8: 'Mexico',
    ATVPDKIKX0DER: 'US',
    A2VIGQ35RCS4UG: 'United Arab Emirates (U.A.E.)',
    A1PA6795UKMFR9: 'Germany',
    ARBP9OOSHTCHU: 'Egypt',
    A1RKKUPIHCS9HS: 'Spain',
    A13V1IB3VIYZZH: 'France',
    A1F83G8C2ARO7P: 'UK',
    A21TJRUUN4KGV: 'India',
    APJ6JRA9NG5V4: 'Italy',
    A1805IZSGTT6HS: 'Netherlands',
    A1C3SOZRARQ6R3: 'Poland',
    A17E79C6D8DWNP: 'Saudi Arabia',
    A2NODRKZP88ZB9: 'Sweden',
    A33AVAJ2PDY3EV: 'Turkey',
    AMEN7PMS3EDWL: 'Belgium',
    A19VAU5U5O7RUS: 'Singapore',
    A39IBJ37TRP1C6: 'Australia',
    A1VC38T7YXB528: 'Japan'
  }
}
