import { INavData } from '@coreui/angular';
import {
  stripeCustomerPortals,
  stripeMode
} from "../../config/app-fe.js";

export const navItems: INavData[] = [
  {
    title: true,
    name: 'Listings'
  },
  {
    name: 'Dashboard',
    url: '/dashboard/overview',
    icon: 'cil-chart'
  },
  {
    name: 'SKUs',
    url: '/dashboard/listings',
    icon: 'cil-list'
  },
  {
    name: 'ASINs',
    url: '/dashboard/asins',
    icon: 'cil-list'
  },
  {
    name: 'Pricing Rules',
    url: '/dashboard/pricing-rules',
    icon: 'cil-dollar'
  },
  {
    name: 'Activity Log',
    url: '/dashboard/activity-log',
    icon: 'cil-list-rich'
  },
  {
    title: true,
    name: 'Inventory'
  },
  {
    name: 'Inventory',
    url: '/dashboard/inventory',
    icon: 'cil-storage'
  },
  {
    name: 'Components',
    url: '/dashboard/components',
    icon: 'cil-mobile'
  },
  {
    name: 'Products',
    url: '/dashboard/products',
    icon: 'cil-devices'
  },
  {
    name: 'Tags',
    url: '/dashboard/tags',
    icon: 'cil-tags'
  },
  {
    name: 'Suppliers',
    url: '/dashboard/suppliers',
    icon: 'cil-truck'
  },
  {
    title: true,
    name: 'Settings'
  },
  {
    name: 'Stores',
    url: '/dashboard/stores',
    icon: 'cil-cart'
  },
  {
    name: 'Users',
    url: '/dashboard/users',
    icon: 'cil-group'
  },
  {
    name: 'Settings',
    url: '/dashboard/settings',
    icon: 'cil-settings'
  },
  {
    name: 'Billing',
    url: 'https://' + stripeCustomerPortals[stripeMode],
    icon: 'cil-credit-card',
    attributes: { target: '_blank' }
  },
  {
    title: true,
    name: 'Management'
  },
  {
    name: 'Clients',
    url: '/dashboard/clients',
    icon: 'cil-group'
  },
  {
    name: 'Plans',
    url: '/dashboard/plans',
    icon: 'cil-wallet'
  },
  {
    name: 'Fulfillment Centers',
    url: '/dashboard/fulfillment-centers',
    icon: 'cil-truck'
  },
  {
    name: 'Tools',
    url: '/dashboard/tools',
    icon: 'cil-menu'
  }
];
