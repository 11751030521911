import { Injectable } from '@angular/core';
import {CanActivate, CanDeactivate, Router} from '@angular/router';
import { UserService } from './user.service';

@Injectable()
export class IsAdminService implements CanActivate, CanDeactivate<any> {

  constructor(private userService: UserService, private router: Router) {}

  canActivate() {
    let isAuthenticated = this.userService.isAuthenticated();
    let isAdmin = this.userService.profile("type") === "admin" || this.userService.profile("type") === "manager";
    if (!isAuthenticated || !isAdmin) this.router.navigate(['/login']);
    return isAuthenticated;
  }

  canDeactivate() {
    return true;
  }
}
