import * as localConfig from '../../../config/local-fe';

export default class Utils {
  static convert(from:any, to:any, amount:any, rates:any) {
    if (from === to) return amount;

    let rate = rates[to][from];

    if (!rate) {
      throw new Error(`No rate found for ${from} to ${to}`);
    }

    return amount * rate;
  }

  static convertAmountIfNeeded(primaryCurrency:any, storeCurrency:any, amount:any, rates:any) {
    if (!primaryCurrency) primaryCurrency = 'USD';
    if (!storeCurrency) storeCurrency = 'USD';
    if (amount === undefined || amount === null) amount = 0;
    if (typeof amount !== 'number') amount = Number(amount);

    if (primaryCurrency === storeCurrency) return amount;
    return Utils.convert(primaryCurrency, storeCurrency, amount, rates);
  }

  static isTenantsInstance() {
    return localConfig.webServer === 'appforhumans.com';
  }
}
