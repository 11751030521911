import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html'
})
export class PrivacyPolicyComponent implements OnInit {

  currentDate = new Date();

  constructor(public router: Router,
              private title: Title,
              public http: HttpClient,
              public changeDetector: ChangeDetectorRef) { }

  ngOnInit() {
    this.title.setTitle("AppForHumans - Privacy Policy");
  }
}
