import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {QueueService} from '../../_services/queue.service';
import {HttpClient} from '@angular/common/http';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {LoadingComponent} from '../../_routes/loading/loading.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrConfigService} from '../../_services/toastr-config.service';
import * as localConfig from '../../../../config/local-fe.js';

@Component({
  selector: 'app-queue-manager',
  templateUrl: './queue-manager.component.html'
})
export class QueueManagerComponent implements OnInit {
  @ViewChild('removeItemWarningModal', {static: false}) public removeItemWarningModal: ModalDirective;

  clickOutsideEnabled = true;
  panelVisible = false;
  activeTab = "in-progress";

  selectedForRemoval = null;
  loadingExport = null;

  constructor(public queue: QueueService,
              public http: HttpClient,
              public modalService: NgbModal,
              public changeDetector: ChangeDetectorRef,
              public toastrConfig: ToastrConfigService) { }

  ngOnInit() {
    this.queue.changeDetector = this.changeDetector;
  }

  toggle() {
    this.panelVisible = !this.panelVisible;
    if (this.panelVisible) {
      this.activeTab = "in-progress";
      this.queue.recalculateStartedItemDuration();
    }
  }

  close() {
    this.panelVisible = false;
  }

  async removeItem() {
    let item = this.selectedForRemoval;
    if (!item) return;

    this.removeItemWarningModal.hide();

    try {
      await this.http.get(localConfig.operationsServer + '/api/queue/remove/?id=' + item.id).toPromise();
      this.queue.toastr.pop(this.toastrConfig.getToast("Operation successfully removed from the queue", "success", 3000));
    }
    catch(e) {}
  }

  async toggleQueue() {
    this.clickOutsideEnabled = false;
    if (!this.queue.data.queueStopped) await this.http.get(localConfig.operationsServer + '/api/queue/stop').toPromise();
    if (this.queue.data.queueStopped) await this.http.get(localConfig.operationsServer + '/api/queue/start').toPromise();

    setTimeout(() => {
      this.clickOutsideEnabled = true;
    }, 1000);
  }

  async downloadData(item) {
    this.showLoading("Please wait while the data is being exported.");

    await this.http.get(localConfig.apiServer + "/api/queue/downloadCSV/?id=" + item.id, {responseType: 'blob'})
      .subscribe(
        (response: any) => {
          this.closeLoading();

          let binaryData = [];
          binaryData.push(response);
          let downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: 'text/csv'}));
          downloadLink.setAttribute('download', "Update via CSV " + item.startedOn + ".csv");
          document.body.appendChild(downloadLink);
          downloadLink.click();
        }
      );
  }

  showLoading(message) {
    this.loadingExport = this.modalService.open(LoadingComponent, {
      backdropClass: 'light-grey-modal',
      windowClass: 'list-modal project-form-modal',
      centered: true,
      backdrop: 'static'
    });

    this.loadingExport.componentInstance.message = message;
  }

  closeLoading() {
    if (this.loadingExport) this.loadingExport.close();
  }

  openTab(tab) {
    this.activeTab = tab;
  }

  fullLabel() {
    if (this.queue.data.unfinished.length === 0) return "No operations scheduled";
    else {
      if (this.queue.data.queueStopped) return "Queue stopped";

      let label = this.queue.data.unfinished.length.toString();
      label += this.queue.data.unfinished.length === 1 ? " operation" : " operations";
      label += " in progress";
      return label;
    }
  }
}
