import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import {UserService} from "./_services/user.service";
import {Title} from "@angular/platform-browser";
import { navItems } from './nav';
import {ToasterConfig} from "angular2-toaster";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ChangePasswordComponent} from "./_routes/dashboard/users/change-password/change-password.component";
import {appName} from "../../config/app-fe.js";
import {HttpClient} from '@angular/common/http';
import * as localConfig from '../../config/local-fe.js';
import {QueueService} from './_services/queue.service';
import Utils from './_helpers/utils';

@Component({
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  app = null;

  public sidebarMinimized = false;
  public navItems = navItems;

  public toasterconfig: ToasterConfig =
    new ToasterConfig({
      showCloseButton: true,
      timeout: 0,
      positionClass: 'toast-top-right'
    });

  page = null;

  adminOnlyPages = ["Settings", "Users", "Stores", "Billing"];
  managerOnlyPages = ["Management", "Clients", "Fulfillment Centers", "Plans", "Tools"];

  titles = {
    'settings': 'Settings',
    'pricing-rules': 'Pricing Rules',
    'listings': 'Listings',
    'asins': 'ASINs',
    'stores': 'Stores',
    'users': 'Users',
    'activity-log': 'Activity Log',
    'inventory': 'Inventory',
    'components': 'Components',
    'products': 'Products',
    'tags': 'Tags',
    'suppliers': 'Suppliers',
    'clients': 'Clients',
    'fulfillment-centers': 'Fulfillment Centers',
    'plans': 'Plans',
    'overview': 'Dashboard'
  };

  isAdminPage = false;
  inited = false;

  impersonating:any = false;

  routerSubscription = null;

  isAppReady = false;

  isTenantsInstance = Utils.isTenantsInstance();

  constructor(private router: Router,
              public userService: UserService,
              private title: Title,
              public modalService: NgbModal,
              public http: HttpClient,
              public changeDetector: ChangeDetectorRef,
              private queueService: QueueService) {}

  ngOnInit() {
    this.impersonating = localStorage.getItem("impersonating");

    try {
      if (this.userService.loggedIn) {
        this.userService.refreshProfile(async () => {
          let tenantID = this.userService.profile("tenantID");

          if (tenantID) {
            let isUnlimitedTenant = await this.isUnlimitedTenant();
            if (isUnlimitedTenant) this.navItems = this.navItems.filter(item => item.name !== 'Billing');
          }

          if (!this.userService.isAdmin) {
            this.navItems = this.navItems.filter(item => this.adminOnlyPages.indexOf(item.name) === -1);
          }
          if (!this.userService.isManager) {
            this.navItems = this.navItems.filter(item => this.managerOnlyPages.indexOf(item.name) === -1);
          }
          if (this.isTenantsInstance) {
            this.navItems = this.navItems.filter(item => item.name !== 'Dashboard');
          }
        });
      }
    }
    catch(e) {}

    this.routerSubscription = this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      this.title.setTitle(appName);
      this.page = null;
      let url = this.router.url;
      if (url.startsWith("/dashboard")) this.isAdminPage = true;
      if (url && url.startsWith('/dashboard/')) url = url.replace("/dashboard/", "");

      Object.keys(this.titles).forEach(title => {
        if (url.startsWith(title)) {
          this.title.setTitle(appName + ": " + this.titles[title]);
          this.page = this.titles[title];
        }
      })

      window.scrollTo(0, 0);

      this.inited = true;
      this.checkAppReady();
      this.setupSubscriptions();

      if (this.userService.profile("justAdded", null) === true) {
        this.changePassword();
      }
    });

    let minimized = window.localStorage.getItem("sidebarMinimized");
    if (minimized === "true") this.sidebarMinimized = true;

    setTimeout(() => {
      this.toggleMinimizedIfMobile();
    }, 500);
  }

  ngOnDestroy() {
    if (this.routerSubscription) this.routerSubscription.unsubscribe();
  }

  toggleMinimizedIfMobile() {
      if (window.innerWidth < 992) this.sidebarMinimized = true;
      setTimeout(() => {
        this.userService.sidebarToggled.next();
      }, 500);
  }

  toggleMinimize(e) {
    window.localStorage.setItem("sidebarMinimized", (e ? "true" : "false"));
    this.sidebarMinimized = e;
    setTimeout(() => {
      this.userService.sidebarToggled.next();
    }, 500);
  }

  async changePassword() {
    const modalRef = this.modalService.open(ChangePasswordComponent, {
      backdropClass: 'light-grey-modal',
      windowClass: 'list-modal',
      centered: true
    });

    try { await modalRef.result; } catch(e) {}

    this.userService.refreshProfile(null);
  }

  async isUnlimitedTenant() {
    let tenantResult:any = await this.http.get(localConfig.apiServer + "/api/client/isUnlimited").toPromise();
    return tenantResult.isUnlimited;
  }

  stopImpersonating() {
    let masterToken = localStorage.getItem("masterToken");
    let masterProfile = localStorage.getItem("masterProfile");

    localStorage.setItem("token", masterToken);
    localStorage.setItem("profile", masterProfile);

    localStorage.removeItem("impersonating");

    this.router.navigate(['/dashboard/clients']);

    setTimeout(() => {
      window.location.reload();
    }, 100);
  }

  async checkAppReady() {
    let isAppReady:any = await this.http.get(localConfig.apiServer + "/api/setting/isAppReady").toPromise();
    this.isAppReady = isAppReady;
  }

  setupSubscriptions() {
    this.queueService.connectSocketIfNotConnected();

    window['io'].socket.on('system', (msg) => {
      if (msg.action === "appReady") {
        this.isAppReady = true;
        this.changeDetector.detectChanges();
        setTimeout(() => {
          document.body.click();
        }, 500);
      }
    });
  }
}
