import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {BehaviorSubject, Subject} from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import {ToasterService as ToastrService} from "angular2-toaster";
import * as $ from 'jquery/dist/jquery.min.js';
import {ToastrConfigService} from './toastr-config.service';
import * as localConfig from '../../../config/local-fe.js';

@Injectable()
export class UserService {

  // Create a stream of logged in status to communicate throughout app
  loggedIn: boolean;
  loggedIn$ = new BehaviorSubject<boolean>(this.loggedIn);
  isLoginActive: boolean = false;
  isAdmin = false;
  isManager = false;
  id = null;
  tenantID = null;
  profileCached = null;
  sidebarToggled: Subject<string> = new Subject<string>();

  constructor(
    private router: Router,
    private http: HttpClient,
    public toastr: ToastrService,
    private zone: NgZone,
    public toastrConfig: ToastrConfigService) {
    if (this.isAuthenticated()) this.afterSignedIn();
    else $("body").addClass("not-logged-in");
  }

  public afterSignedIn() {
    this.setLoggedIn(true);
    this.refreshProfile(null);
  }

  public getUserProfile() {
    let profile = localStorage.getItem("profile");
    if (!profile) return false;
    let profileJSON = null;
    try {
      profileJSON = JSON.parse(profile);
    } catch(e) {}
    if (!profileJSON) return false;
    return profileJSON;
  }

  public isAuthenticated() {
    // Check if there's an unexpired access token
    const helper = new JwtHelperService();
    const myRawToken = localStorage.getItem('token');
    return !helper.isTokenExpired(myRawToken);
  }

  public signup(registerModel: any, loading: any) {
    loading = true;
    this.http.post(localConfig.apiServer + '/api/auth/signup', registerModel).subscribe(
      data => {
        this._setSession(data["data"]);
        this.router.navigate(["/dashboard/"], { queryParams: {welcome: 'true'}});
      },
      error => {
        let errorMessage = "An error occurred trying to sign you up, please check the information and try again";
        if (error.error && error.error.details && error.error.details.indexOf("A record with that `email` already exists") != -1)
          errorMessage = "This e-mail is already registered, please try to sign in or retrieve your password";
        this.toastr.pop(this.toastrConfig.getToast(errorMessage, "error"));
        loading = false;
      });
  }

  public refreshProfile(callback) {
    if (this.profileCached) {
      if (callback) callback();
      return;
    }
    else {
      this.http.get(localConfig.apiServer + '/api/user/refresh').subscribe(
        (data:any) => {
          localStorage.setItem('profile', JSON.stringify(data));
          if (data.type === 'admin') this.isAdmin = true;
          if (data.type === 'manager') {
            this.isAdmin = true;
            this.isManager = true;
          }

          this.id = data.id;
          this.tenantID = data.tenantID;
          this.profileCached = true;
          setTimeout(() => {
            this.profileCached = false;
          }, 10000);
          if (callback) callback();
        });
    }
  }

  public async signin(loginModel: any) {
    this.http.post(localConfig.apiServer + '/api/auth/signin', loginModel).subscribe(
      async (data) => {
        this._setSession(data);
        this.toastr.clear();
        await this.router.navigate(["/dashboard/"]);
        window.location.reload();
        this.afterSignedIn();
      },
      (res) => {
        let errorMessage = "Incorrect credentials, please try again."
        if (res.error === 'Forbidden') errorMessage = "You do not have access to this application. Please contact an administrator.";
        this.toastr.pop(this.toastrConfig.getToast(errorMessage, "error"));
      });
  }

  public recover(recoverModel: any) {
    this.http.post(localConfig.apiServer + '/api/auth/recover', recoverModel).subscribe(
      () => {
        this.toastr.pop(this.toastrConfig.getToast('An e-mail has been sent to ' + recoverModel.email + ' with further instructions', "success", 3000));
        this.router.navigate(["/dashboard/"]);
      },
      () => {
        let errorMessage = "An error occurred trying to recover your password, please check the information and try again";
        this.toastr.pop(this.toastrConfig.getToast(errorMessage, "error"));
      });
  }

  public resetpass(resetpassModel: any) {
    this.http.post(localConfig.apiServer + '/api/auth/resetpass', resetpassModel).subscribe(
      () => {
        this.toastr.pop(this.toastrConfig.getToast('Your password was updated', "success", 3000));
        this.router.navigate(["/dashboard/"]);
      },
      () => {
        let errorMessage = "An error occurred trying to update your password, please check the information and try again";
        this.toastr.pop(this.toastrConfig.getToast(errorMessage, "error"));
      });
  }

  private _setSession(data) {
    localStorage.setItem('token', data.token);
    localStorage.setItem('profile', JSON.stringify(data.user));
    this.setLoggedIn(true);
  }

  private setLoggedIn(value: boolean) {
    // Update login status subject
    this.loggedIn$.next(value);
    this.loggedIn = value;
    if (this.loggedIn) $("body").removeClass("not-logged-in");
  }

  logout() {
    // Remove tokens and profile and update login status subject
    localStorage.removeItem('token');
    localStorage.removeItem('profile');
    localStorage.removeItem("masterToken");
    localStorage.removeItem("masterProfile");
    localStorage.removeItem("impersonating");
    this.setLoggedIn(false);
    $("body").empty();
    this.router.navigate(["/login"]);
  }

  reloadPage(home) {
    this.zone.runOutsideAngular(() => {
      if (home === true) location.href = '/';
      else location.reload();
    });
  }

  toggleClass() {
    this.isLoginActive = !this.isLoginActive;
    this.updateClass();
  }

  updateClass() {
    if (this.isLoginActive) {
      setTimeout(function() {
        $('.front .form-button-container').hide();
        $('.back .form-button-container').show();
      }, 300);
    }
    else {
      setTimeout(function() {
        $('.front .form-button-container').show();
        $('.back .form-button-container').hide();
      }, 300);
    }
  }

  profile(key, value?) {
    let profile = this.getUserProfile();
    if (value === null || value === undefined) return profile[key];
    else {
      profile[key] = value;
      localStorage.setItem('profile', JSON.stringify(profile));
    }
  }
}
