import { Injectable } from '@angular/core';
import {CanActivate, CanDeactivate, Router} from '@angular/router';
import { UserService } from './user.service';
import {ListingsComponent} from '../_routes/dashboard/listings/listings.component';

@Injectable()
export class GuardService implements CanActivate, CanDeactivate<ListingsComponent> {

  constructor(private userService: UserService, private router: Router) {}

  canActivate() {
    let isAuthenticated = this.userService.isAuthenticated();
    if (!isAuthenticated) this.router.navigate(['/login']);
    return isAuthenticated;
  }

  canDeactivate(component: ListingsComponent) {
    if (component && component.hasUpdates()) {
      let discard = window.confirm('You have unsaved changes. Are you sure you wish to discard them?');
      if (discard) component.clearUpdates();
      return discard;
    }
    else return true;
  }
}
