import {Injectable} from '@angular/core';
import {Toast} from 'angular2-toaster';

@Injectable({
  providedIn: 'root',
})
export class ToastrConfigService {
  constructor() {}

  getToast(message, type, timeout?) {
    let toast: Toast = {
      type: type,
      title: '',
      body: message,
      showCloseButton: true
    };

    if (timeout) {
      toast.timeout = timeout;
    }

    return toast;
  }
}
